// src/components/MeetTheFoundersSection.js
import React from 'react';

const MeetTheFoundersSection = () => {
  return (
    <section className="bg-white py-24">
      <div className="container mx-auto px-6 max-w-[1080px]">
        
        {/* Section Header with ice-cream-standard font and weight 600 */}
        <h2 
          className="text-5xl mb-2 text-center leading-tight" 
          style={{ 
            fontFamily: '"ice-cream-standard", sans-serif',  // Adobe font
            fontWeight: '600',  // Weight 600 for bold
            color: '#310068'  // Header color
          }}
        >
          Meet The Founders
        </h2>
        
        {/* Purple Divider under the header */}
        <div className="border-t-4 border-[#C7B2D6] w-24 mx-auto mb-10"></div>

        {/* Founder 1 Section */}
        <div className="flex flex-col md:flex-row items-center mb-16">
          {/* Left Column - Image */}
          <div className="md:w-1/2 p-4">
            <img
              src={`${process.env.PUBLIC_URL}/images/founder_1.png`}
              alt="Founder 1"
              className="w-full rounded-lg"
            />
          </div>
          {/* Right Column - Text */}
          <div className="md:w-1/2 p-4">
            {/* Subheader with ice-cream-standard font and weight 400 */}
            <h4 
              className="text-3xl mb-4"
              style={{ 
                fontFamily: '"ice-cream-standard", sans-serif',  // Adobe font
                fontWeight: '400',  // Weight 400 for normal
                color: '#310068'  // Subheader color
              }}
            >
              Eniola
            </h4>
            {/* Title with ice-cream-standard font */}
            <p 
              className="uppercase mb-2 text-lg tracking-wide"
              style={{
                fontFamily: '"ice-cream-standard", sans-serif',  // Adobe font
                fontWeight: '400',  // Normal weight for title
                color: '#553293'  // Title color
              }}
            >
              FOUNDER & CEO
            </p>
            {/* Paragraph with consistent font styling */}
            <p 
              className="text-gray-700 text-lg"
              style={{
                fontFamily: '"halcom", sans-serif',  // Keeping paragraph consistent with other sections
                fontWeight: '400',  // Normal weight for readability
                lineHeight: '1.6',  // Improved readability with increased line height
              }}
            >
              Eniola is a dedicated mom with over a decade of experience in children’s education, both inside and outside the classroom. As a self-taught software engineer and AI enthusiast, she is driven by her passion for teaching children how to read. During the COVID-19 pandemic, Eniola realized the difficulties of making new parenting friends and finding playdates for her son, which led her to create KidMatch.
            </p>
          </div>
        </div>

        {/* Founder 2 Section */}
        <div className="flex flex-col md:flex-row-reverse items-center">
          {/* Left Column - Image */}
          <div className="md:w-1/2 p-4">
            <img
              src={`${process.env.PUBLIC_URL}/images/founder_1.png`}
              alt="Founder 2"
              className="w-full rounded-lg"
            />
          </div>
          {/* Right Column - Text */}
          <div className="md:w-1/2 p-4">
            {/* Subheader with ice-cream-standard font and weight 400 */}
            <h4 
              className="text-3xl mb-4"
              style={{ 
                fontFamily: '"ice-cream-standard", sans-serif',  // Adobe font
                fontWeight: '400',  // Weight 400 for normal
                color: '#310068'  // Subheader color
              }}
            >
              Amarachi
            </h4>
            {/* Title with ice-cream-standard font */}
            <p 
              className="uppercase mb-2 text-lg tracking-wide"
              style={{
                fontFamily: '"ice-cream-standard", sans-serif',  // Adobe font
                fontWeight: '400',  // Normal weight for title
                color: '#553293'  // Title color
              }}
            >
              Developer
            </p>
            {/* Paragraph with consistent font styling */}
            <p 
              className="text-gray-700 text-lg"
              style={{
                fontFamily: '"halcom", sans-serif',  // Keeping paragraph consistent with other sections
                fontWeight: '400',  // Normal weight for readability
                lineHeight: '1.6',  // Improved readability with increased line height
              }}
            >
              Amarachi brings over a decade of experience in software product development to KidMatch. As a mother of four, including two children on the spectrum, she intimately understands the challenges of finding playdates that support her children's development and learning through play. Amarachi’s journey began with starting a local playgroup community in Africa, which grew to over a local family site. She now brings her passion for community building and technology to KidMatch.
            </p>
          </div>
        </div>

      </div>
    </section>
  );
};

export default MeetTheFoundersSection;
