// src/components/Footer.js

import React from 'react';
import { FaFacebookF, FaTwitter, FaYoutube } from 'react-icons/fa'; // Importing icons from React Icons
import { Link } from 'react-router-dom'; // Import Link from react-router-dom

const Footer = () => {
  return (
    <footer className="bg-[#310068] text-white py-8">
      <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 text-center md:text-left">
        {/* Column 1: Logo */}
        <div className="flex flex-col items-center md:items-start">
          <Link to="/">
            <img
              src={`${process.env.PUBLIC_URL}/images/logo.png`} // Update with the correct path to your logo image
              alt="KidMatch Logo"
              className="w-48" // Adjusted the logo size
            />
          </Link>
        </div>

        {/* Column 2: Email */}
        <div className="flex flex-col items-center md:items-start mt-4 md:mt-0">
          <h3 
            className="mb-2"
            style={{
              fontFamily: '"halcom", sans-serif',  // Halcom font
              fontWeight: '600',  // Semi-bold weight
            }}
          >
            connect@kidmatch.co
          </h3>
        </div>

        {/* Column 3: Address */}
        <div className="flex flex-col items-center md:items-start mt-4 md:mt-0">
          <p 
            className="mb-2"
            style={{
              fontFamily: '"halcom", sans-serif',  // Halcom font
              fontWeight: '600',  // Semi-bold weight
            }}
          >
            107 W 63rd St, Kansas City, MO
          </p>
          <p 
            style={{
              fontFamily: '"halcom", sans-serif',  // Halcom font
              fontWeight: '600',  // Semi-bold weight
            }}
          >
            64113
          </p>
        </div>

        {/* Column 4: Social Media Links */}
        <div className="flex flex-col items-center md:items-start mt-4 md:mt-0">
          {/* "Keep In Touch" with ice-cream-standard font */}
          <h3 
            className="mb-4"
            style={{
              fontFamily: '"ice-cream-standard", sans-serif',  // Adobe font
              fontWeight: '400',  // Normal weight
            }}
          >
            Keep In Touch
          </h3>
          <div className="flex gap-4">
            {/* Social Media Icons */}
            <a href="#" aria-label="Facebook" className="w-8 h-8 bg-[#FED700] rounded-full flex items-center justify-center">
              <FaFacebookF className="text-[#310068]" />
            </a>
            <a href="#" aria-label="Twitter" className="w-8 h-8 bg-[#FED700] rounded-full flex items-center justify-center">
              <FaTwitter className="text-[#310068]" />
            </a>
            <a href="#" aria-label="YouTube" className="w-8 h-8 bg-[#FED700] rounded-full flex items-center justify-center">
              <FaYoutube className="text-[#310068]" />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
