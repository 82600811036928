// src/components/AboutUsSection.js
import React from 'react';

const AboutUsSection = () => {
  return (
    <section className="bg-white py-16">
      {/* Container with max-width of 1080px */}
      <div className="container mx-auto px-6 max-w-[1080px]">
        {/* Header with ice-cream-standard font and weight 600 */}
        <h1 
          className="text-5xl mb-2 text-center leading-tight" 
          style={{ 
            fontFamily: '"ice-cream-standard", sans-serif',  // Adobe font
            fontWeight: '600',  // Weight 600 for bold
            color: '#310068'  // Header color
          }}
        >
          About Us
        </h1>
        
        {/* Divider under the header with specified color */}
        <div className="border-t-4 border-[#C7B2D6] w-24 mx-auto mb-10"></div>

        <div className="flex flex-col md:flex-row items-center">
          {/* Left Column - Image */}
          <div className="md:w-1/2 p-4 flex justify-center">
            <img
              src={`${process.env.PUBLIC_URL}/images/about_us_image.png`}
              alt="About Us"
              className="w-3/4 rounded-lg" // Adjust width for a smaller image
            />
          </div>
          {/* Right Column - Text */}
          <div className="md:w-1/2 p-4">
            {/* Subheader with ice-cream-standard font and weight 400 */}
            <h4 
              className="text-2xl mb-4"
              style={{ 
                fontFamily: '"ice-cream-standard", sans-serif',  // Adobe font
                fontWeight: '400',  // Weight 400 for normal
                color: '#553293'  // Subheader color
              }}
            >
              Our Story
            </h4>
            {/* Paragraph with consistent font styling */}
            <p 
              className="text-gray-700 text-lg"
              style={{
                fontFamily: '"halcom", sans-serif',  // Keeping paragraph consistent with other sections
                fontWeight: '400',  // Normal weight for readability
                lineHeight: '1.6',  // Improved readability with increased line height
              }}
            >
              KidMatch was born out of a shared frustration experienced by two mothers who understood the complexities of modern parenting. Eniola and Amarachi, both passionate about education, technology, and community-building, realized how challenging it was to find meaningful connections and playdates for their children. The COVID-19 pandemic only intensified this struggle, as it became increasingly difficult to balance work, life, and the need to foster social interactions for their kids. KidMatch is the solution they wished they had—a platform that seamlessly connects parents, simplifies playdate coordination, and builds supportive communities.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUsSection;
